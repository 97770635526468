let SignsCheck = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取体征检查首页
     * @param {any} success
     * @param {any} error
     */
    this.SignsCheck = function (success, error) {
        var url = ServiceBaseAddress + '/api/SignsCheck/Index';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取体征检查详情
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params,success, error) {
        var url = ServiceBaseAddress + '/api/SignsCheck/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { SignsCheck }
